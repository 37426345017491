import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

import { JobCompanyModel } from '../_models/JobCompanyModel';
import { JobCompanyCreateModel } from '../_models/JobCompanyCreateModel';
import { JobCompanyCreateResponseModel } from '../_models/JobCompanyCreateResponseModel';
import { JobFlagCompanyForUserModel } from '../_models/JobFlagCompanyForUserModel';
import { JobUserCompanyModel } from '../_models/JobUserCompanyModel';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization':'Bearer '+sessionStorage.getItem('access_token')
  })
};

@Injectable({
  providedIn: 'root'
})

export class JobCompanyApiService {

  private _sptUrl: string = environment.appSptApiServerUrl+'/api/v1.1';
  // private _sptUrl: string = "https://sptapi.src.xyz/api/v1.1";
  // private _sptUrl: string = "https://localhost:7210/api/v1.1";

  constructor(private httpClient: HttpClient) { }

  getAll(): Observable<JobCompanyModel[]> {

    let httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+sessionStorage.getItem('access_token')
      })
    };

    const url = `${this._sptUrl}/JobCompanyGetAll`;
    let x = this.httpClient.get<JobCompanyModel[]>(url, httpOptions2);
    return x;
  }

  getAllByUser(): Observable<JobUserCompanyModel[]> {

    let httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+sessionStorage.getItem('access_token')
      })
    };

    const url = `${this._sptUrl}/JobGetAllCompanyWithUserFlag`;
    let x = this.httpClient.get<JobUserCompanyModel[]>(url, httpOptions2);
    return x;
  }

  getById(id:number): Observable<JobCompanyModel> {

    let httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+sessionStorage.getItem('access_token')
      })
    };

    const url = `${this._sptUrl}/JobGet/${id}`;
    return this.httpClient.get<JobCompanyModel>(url, httpOptions2);
  }

  postByModel(item: JobCompanyCreateModel) {

    let httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+sessionStorage.getItem('access_token')
      })
    };


    //let body = JSON.stringify(item); //its will convert all datatype in JSON into strings
    const url = `${this._sptUrl}/JobCompanyAdd`;

    item.IsFlagged = false;
    return this.httpClient.post<JobCompanyCreateResponseModel>(url, item, httpOptions2).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
          return throwError(() => error);
        } 
        else {
          // The backend returned an unsuccessful response code.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
          return throwError(() => error);
        }
      })
    );
  }

  editByModel(item: JobCompanyModel) {

    let httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+sessionStorage.getItem('access_token')
      })
    };

    //let body = JSON.stringify(item); //its will convert all datatype in JSON into strings
    const url = `${this._sptUrl}/JobCompanyEdit`;

    return this.httpClient.put(url, item, httpOptions2).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
          return throwError(() => error);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
          return throwError(() => error);
        }
      })
    );
  }

  editFlagByModel(jobCompanyModel: JobCompanyModel) {

    let httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+sessionStorage.getItem('access_token')
      })
    };

    //let body = JSON.stringify(item); //its will convert all datatype in JSON into strings
    const url = `${this._sptUrl}/JobCompanyEditFlag`;

    return this.httpClient.put(url, jobCompanyModel, httpOptions2).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
          return throwError(() => error);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
          return throwError(() => error);
        }
      })
    );
  }

  flagCompanyForUser(jobFlagCompanyForUserModel: JobFlagCompanyForUserModel) {

    let httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+sessionStorage.getItem('access_token')
      })
    };

    const url = `${this._sptUrl}/JobFlagCompanyForUser`;

    return this.httpClient.post(url, jobFlagCompanyForUserModel, httpOptions2).pipe(
      retry(1), 
      catchError((error: HttpErrorResponse) => {
        
        if (error.error instanceof ErrorEvent) {
          console.error('An error occurred:', error.error.message);
          return throwError(() => error);
        } 
        else {
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
          return throwError(() => error);
        }

      })
    );
  }

  putById(item: JobCompanyModel) {

    let httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+sessionStorage.getItem('access_token')
      })
    };

    //you must pass item.Id = 0, as it was set to Auto Increment in Database
    const url = `${this._sptUrl}/JobEdit`;
    return this.httpClient.put<JobCompanyModel>(url, item, httpOptions2);
  }

  deleteById(id:string):Observable<{}> {

    let httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+sessionStorage.getItem('access_token')
      })
    };

    const url = `${this._sptUrl}/JobCompanyDeleteById?id=${id}`;
    return this.httpClient.delete(url, httpOptions2);
  }

  getItems(): Observable<JobCompanyModel[]> {
    const url = `${this._sptUrl}/JobsGetList`;
    return this.httpClient.get<JobCompanyModel[]>(url);
  }

  postItems(item: JobCompanyModel) {
    const url = `${this._sptUrl}/JobsAddList`;
    return this.httpClient.post<JobCompanyModel>(this._sptUrl, item, httpOptions);
  }

  putItems(item:JobCompanyModel) {
    const url = `${this._sptUrl}/JobsEditList`;
    return this.httpClient.put<JobCompanyModel>(url, item, httpOptions); //<IEmployee> can be removed, if you wish
  }

  deleteItems(id: number):Observable<{}> {
    const url = `${this._sptUrl}/JobsDeleteList`;
    return this.httpClient.delete<JobCompanyModel>(url, httpOptions); //<IEmployee> can be removed, if you wish
  }
}
