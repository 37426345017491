import { Component } from '@angular/core';

@Component({
  selector: 'app-credentials-layout',
  templateUrl: './credentials-layout.component.html',
  styleUrls: ['./credentials-layout.component.css']
})
export class CredentialsLayoutComponent {

}
