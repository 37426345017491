import { Component } from '@angular/core';

@Component({
  selector: 'app-bookings-sku-form',
  templateUrl: './bookings-sku-form.component.html',
  styleUrls: ['./bookings-sku-form.component.css']
})
export class BookingsSkuFormComponent {

}
