<div class="w3-main" style="margin-left:250px; padding: 64px;">
    <div>
        <h1>BookingsSkuList</h1>
    </div>


    <div>
        <button (click)=onGet() type="button" style="padding:10px; margin-bottom:10px">Refresh Data</button>

        <table class="table">
            <thead>
                <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Id</th>
                    <th scope="col">SkuNumber</th>
                    <th scope="col">Name</th>
                    <th scope="col">Currency</th>
                    <th scope="col">SalePrice</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let _skuModel of _skuModelList; let i = index">
                    <th scope="row">{{i}}</th>
                    <td>{{_skuModel.Id}}</td>
                    <td>{{_skuModel.SkuNumber}}</td>
                    <td>{{_skuModel.Name}}</td>
                    <td>{{_skuModel.Currency}}</td>
                    <td>{{_skuModel.SalePrice}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>