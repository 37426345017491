import { Component } from '@angular/core';
import { SkuApiService } from 'src/app/_services/sku-api.service';
import { SkuModel } from 'src/app/_models/SkuModel';

@Component({
  selector: 'app-bookings-sku-list',
  templateUrl: './bookings-sku-list.component.html',
  styleUrls: ['./bookings-sku-list.component.css']
})
export class BookingsSkuListComponent {
  public _skuModelList: SkuModel[] = [];

  constructor(private _skuApiService: SkuApiService) { }

  ngOnInit(): void {
      this.onGet();
  }

  onGet() {
    this._skuApiService.skuGetAll().subscribe(responseData => this._skuModelList = responseData);
    console.log("Sku data fetched from GetAll API");
  }

}
