import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { JobApplicationModel } from '../_models/JobApplicationModel';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization':'Bearer '+sessionStorage.getItem('access_token')
  })
};

@Injectable({
  providedIn: 'root'
})
export class JobApplicationApiService {

  private _sptUrl: string = environment.appSptApiServerUrl+'/api/v1.1';

  constructor(private httpClient: HttpClient) { }

  postByModel(payload: JobApplicationModel) {

    let httpOptions2 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+sessionStorage.getItem('access_token')
      })
    };

    //let body = JSON.stringify(item); //its will convert all datatype in JSON into strings
    const url = `${this._sptUrl}/JobApplicationAdd`;

    console.log(url);

    return this.httpClient.post<JobApplicationModel>(url, payload, httpOptions2).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
          return throwError(() => error);
        } else {
          // The backend returned an unsuccessful response code.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
          return throwError(() => error);
        }
      })
    );
  }
}
