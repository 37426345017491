import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JobApplicationModel } from 'src/app/_models/JobApplicationModel';
import { JobApplicationApiService } from 'src/app/_services/job-application-api.service';
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-job-application',
  templateUrl: './job-application.component.html',
  styleUrls: ['./job-application.component.css']
})
export class JobApplicationComponent implements OnInit {

  public isLocal: boolean =  environment.isLocal;

  jobApplicationForm: FormGroup  = new FormGroup({});

  constructor(private fb: FormBuilder, private _jobApplicationApiService: JobApplicationApiService) {
   }

  ngOnInit(): void {
    this.jobApplicationForm = this.fb.group({
      PositionId: [''],
      Name: this.fb.group({
        NamePrefix: [''],
        FirstName: [''],
        MiddleName: [''],
        LastName: [''],
        NameSuffix: ['']
      }),
      Address: this.fb.group({
        AddressLine1: [''],
        AddressLine2: [''],
        AddressCity: [''],
        AddressState: [''],
        AddressCode: ['']
      }),
      Email: this.fb.group({
        EmailAddress: [''],
        EmailType: ['']
      }),
      Phone: this.fb.group({
        PhoneNumber: [''],
        PhoneCountryCode: [''],
        PhoneExtension: [''],
        PhoneDeviceType: ['']
      }),
      WorkExperience: this.fb.array([this.createWorkExperienceFormGroup()]),
      Education: this.fb.array([this.createEducationFormGroup()]),
      Attachments: this.fb.array([this.createAttachmentsFormGroup()]),
      Websites: this.fb.array([this.createWebsitesFormGroup()]),
      Questions: this.fb.array([this.createQuestionsFormGroup()]),
      SelfIdentification: this.fb.group({
        Gender: [''],
        RaceEthnicity: [''],
        VeteranStatus: ['']
      }),
      IsSubmitted: [false],
      Status: [''],
      IsActive: [true],
      CreatedBy: [''],
      CreatedOn: [''],
      UpdatedBy: [''],
      UpdatedOn: ['']
    });
  }

  createWorkExperienceFormGroup(): FormGroup {
    return this.fb.group({
      JobTitle: [''],
      Company: [''],
      IsCurrentCompany: [false],
      StartDate: [''],
      EndDate: [''],
      RoleDescription: ['']
    });
  }

  addWorkExperience(): void {
    this.WorkExperience.push(this.createWorkExperienceFormGroup());
  }

  removeWorkExperience(index: number): void {
    this.WorkExperience.removeAt(index);
  }

  get WorkExperience(): any {
    return this.jobApplicationForm.get('WorkExperience') as FormArray;
  }

  createEducationFormGroup(): FormGroup {
    return this.fb.group({
      InstitutionName: [''],
      Degree: [''],
      FieldOfStudy: [''],
      StartDate: [''],
      EndDate: ['']
    });
  }

  addEducation(): void {
    this.Education.push(this.createEducationFormGroup());
  }

  removeEducation(index: number): void {
    this.Education.removeAt(index);
  }

  get Education(): any {
    return this.jobApplicationForm.get('Education') as FormArray;
  }

  createAttachmentsFormGroup(): FormGroup {
    return this.fb.group({
      AttachmentId: [''],
      AttachmentName: [''],
      AttachmentUrl: [''],
      AttachmentLocation: [''],
      AttachmentType: [''],
      AttachmentDescription: [''],
      AttachmentSize: ['']
    });
  }

  addAttachment(): void {
    this.Attachments.push(this.createAttachmentsFormGroup());
  }

  removeAttachment(index: number): void {
    this.Attachments.removeAt(index);
  }

  get Attachments(): any {
    return this.jobApplicationForm.get('Attachments') as FormArray;
  }

  createWebsitesFormGroup(): FormGroup {
    return this.fb.group({
      WebsiteName: [''],
      WebsiteUrl: ['']
    });
  }

  addWebsite(): void {
    this.Websites.push(this.createWebsitesFormGroup());
  }

  removeWebsite(index: number): void {
    this.Websites.removeAt(index);
  }

  get Websites(): any {
    return this.jobApplicationForm.get('Websites') as FormArray;
  }

  createQuestionsFormGroup(): FormGroup {
    return this.fb.group({
      QuestionId: [''],
      QuestionDescription: [''],
      QuestionType: [''],
      QuestionAnswer: ['']
    });
  }

  addQuestion(): void {
    this.Questions.push(this.createQuestionsFormGroup());
  }

  removeQuestion(index: number): void {
    this.Questions.removeAt(index);
  }
  get Questions(): any {
    return this.jobApplicationForm.get('Questions') as FormArray;
  }

 onSubmit(): void {
    if (this.jobApplicationForm.valid) {
      const formData = this.jobApplicationForm.getRawValue() as JobApplicationModel;
      console.log(formData);


      this._jobApplicationApiService.postByModel(formData).subscribe({
        next: (n: any) => {
  
          // this._successResponse = n;
          // this._errorResponse = {};
  
          // this._showDangerAlert = false;
          // this._showSuccessAlert = true;
          // this._showWarningAlert = false;
          // this._showInfoAlert = false;
  
          console.log("onPost() Success response received");
  
          // this.JobCompanyAddFormIdFormGroupDirective.resetForm();
  
          // this.onGetAllByUser();
          console.log("called onSubmit()");
        },
        error: (e: Error) => {
          // this._errorResponse = e;
          // this._successResponse = {};
          // this._showDangerAlert = true;
          // this._showSuccessAlert = false;
          // this._showWarningAlert = false;
          // this._showInfoAlert = false;

          console.log(e);
        },
        complete() {
          console.log("onPost() Completed");
        }
      });





    } 
    else {
      console.log('Please fill out all required fields.');
    }
  }
}
