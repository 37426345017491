import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.idpAuthIssuer,
  redirectUri: environment.idpAuthRedirectUri,
  clientId: environment.idpAuthClientId,
  responseType: 'code',
  scope: environment.idpAuthScope,
  postLogoutRedirectUri: environment.appHostUrl,
  preserveRequestedRoute: true,
  showDebugInformation: true,
  timeoutFactor: 0.01,
};
