import { Component } from '@angular/core';

@Component({
  selector: 'app-study-nav-side-bar',
  templateUrl: './study-nav-side-bar.component.html',
  styleUrls: ['./study-nav-side-bar.component.css']
})
export class StudyNavSideBarComponent {

}
