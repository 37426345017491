<div class="w3-main" style="margin-left:250px; padding: 64px;">

    <div *ngIf="isLocal">{{ jobApplicationForm.value | json }}</div>

    <div class="container">
        <form [formGroup]="jobApplicationForm" (ngSubmit)="onSubmit()" class="form">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Job Application Form</mat-card-title>
                </mat-card-header>
                <mat-card-content>

                    <mat-form-field appearance="fill">
                        <mat-label>Position Id</mat-label>
                        <input matInput formControlName="PositionId">
                        <mat-error>Please enter a position ID.</mat-error>
                    </mat-form-field>

                    <div formArrayName="Name">
                        <h3>Name</h3>
                        <mat-form-field appearance="fill">
                            <mat-label>Name Prefix </mat-label>
                            <input matInput formControlName="NamePrefix">
                            <mat-error>Please enter a name prefix.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>First Name </mat-label>
                            <input matInput formControlName="FirstName">
                            <mat-error>Please enter a first name.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Middle Name</mat-label>
                            <input matInput formControlName="MiddleName">
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Last Name </mat-label>
                            <input matInput formControlName="LastName">
                            <mat-error>Please enter a last name.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Name Suffix</mat-label>
                            <input matInput formControlName="NameSuffix">
                        </mat-form-field>
                    </div>

                    <div formArrayName="Address">
                        <h3>Address</h3>
                        <mat-form-field appearance="fill">
                            <mat-label>Address Line 1 </mat-label>
                            <input matInput formControlName="AddressLine1">
                            <mat-error>Please enter an address line 1.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Address Line 2</mat-label>
                            <input matInput formControlName="AddressLine2">
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>City </mat-label>
                            <input matInput formControlName="AddressCity">
                            <mat-error>Please enter a city.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>State </mat-label>
                            <input matInput formControlName="AddressState">
                            <mat-error>Please enter a state.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Postal Code </mat-label>
                            <input matInput formControlName="AddressCode">
                            <mat-error>Please enter a postal code.</mat-error>
                        </mat-form-field>
                    </div>

                    <h3>Contact Information</h3>
                    <div formArrayName="Email">
                    <mat-form-field appearance="fill">
                        <mat-label>Email Address </mat-label>
                        <input matInput formControlName="EmailAddress" type="email">
                        <mat-error>Please enter a valid email address.</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Email Type </mat-label>
                        <input matInput formControlName="EmailType">
                        <mat-error>Please enter an email type.</mat-error>
                    </mat-form-field>
                </div>

                <div formArrayName="Phone">
                    <mat-form-field appearance="fill">
                        <mat-label>Phone Number </mat-label>
                        <input matInput formControlName="PhoneNumber">
                        <mat-error>Please enter a phone number.</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Country Code </mat-label>
                        <input matInput formControlName="PhoneCountryCode">
                        <mat-error>Please enter a country code.</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Extension</mat-label>
                        <input matInput formControlName="PhoneExtension">
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Device Type</mat-label>
                        <input matInput formControlName="PhoneDeviceType">
                    </mat-form-field>
                </div>

                <h3>Work Experience</h3>
                    <div formArrayName="WorkExperience">
                        <div *ngFor="let workExperience of WorkExperience.controls; let i = index" [formGroupName]="i">
                            <h4>Work Experience {{ i + 1 }}</h4>

                            <mat-form-field appearance="fill">
                                <mat-label>Job Title </mat-label>
                                <input matInput formControlName="JobTitle">
                                <mat-error>Please enter a job title.</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Company </mat-label>
                                <input matInput formControlName="Company">
                                <mat-error>Please enter a company name.</mat-error>
                            </mat-form-field>

                            <mat-checkbox formControlName="IsCurrentCompany">Currently employed by this
                                company</mat-checkbox>

                            <mat-form-field appearance="fill">
                                <mat-label>Start Date </mat-label>
                                <input matInput formControlName="StartDate">
                                <mat-error>Please enter a start date.</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>End Date</mat-label>
                                <input matInput formControlName="EndDate">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Role Description</mat-label>
                                <textarea matInput formControlName="RoleDescription"></textarea>
                            </mat-form-field>

                            <div class="button-row">
                                <button mat-raised-button color="warn" (click)="removeWorkExperience(i)">Remove Work
                                    Experience</button>
                            </div>

                        </div>

                        <div class="button-row">
                            <button mat-raised-button color="primary" (click)="addWorkExperience()">Add Work
                                Experience</button>
                        </div>

                    </div>

                    <h3>Education</h3>
                    <div formArrayName="Education">
                        <div *ngFor="let education of Education.controls; let i=index" [formGroupName]="i">
                            <h4>Education {{ i + 1 }}</h4>

                            <mat-form-field appearance="fill">
                                <mat-label>Institution Name </mat-label>
                                <input matInput formControlName="InstitutionName">
                                <mat-error>Please enter an institution name.</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Degree </mat-label>
                                <input matInput formControlName="Degree">
                                <mat-error>Please enter a degree.</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Field of Study </mat-label>
                                <input matInput formControlName="FieldOfStudy">
                                <mat-error>Please enter a field of study.</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                                <mat-label>Start Date </mat-label>
                                <input matInput formControlName="StartDate">
                                <mat-error>Please enter a start date.</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>End Date</mat-label>
                                <input matInput formControlName="EndDate">
                            </mat-form-field>

                            <div class="button-row">
                                <button mat-raised-button color="warn" (click)="removeEducation(i)">Remove
                                    Education</button>
                            </div>

                        </div>

                        <div class="button-row">
                            <button mat-raised-button color="primary" (click)="addEducation()">Add Education</button>
                        </div>

                    </div>

                    <h3>Attachments</h3>
                    <div formArrayName="Attachments">
                        <div *ngFor="let attachment of Attachments.controls; let i=index" [formGroupName]="i">
                            <h4>Attachment {{ i + 1 }}</h4>

                            <mat-form-field appearance="fill">
                                <mat-label>Attachment Name</mat-label>
                                <input matInput formControlName="AttachmentName">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Attachment URL</mat-label>
                                <input matInput formControlName="AttachmentUrl">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Attachment Location</mat-label>
                                <input matInput formControlName="AttachmentLocation">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Attachment Type</mat-label>
                                <input matInput formControlName="AttachmentType">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Attachment Description</mat-label>
                                <textarea matInput formControlName="AttachmentDescription"></textarea>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Attachment Size</mat-label>
                                <input matInput formControlName="AttachmentSize">
                            </mat-form-field>

                            <div class="button-row">
                                <button mat-raised-button color="warn" (click)="removeAttachment(i)">Remove
                                    Attachment</button>
                            </div>

                        </div>

                        <div class="button-row">
                            <button mat-raised-button color="primary" (click)="addAttachment()">Add Attachment</button>
                        </div>

                    </div>

                    <h3>Websites</h3>
                    <div formArrayName="Websites">
                        <div *ngFor="let website of Websites.controls; let i=index" [formGroupName]="i">
                            <h4>Website {{ i + 1 }}</h4>

                            <mat-form-field appearance="fill">
                                <mat-label>Website Name </mat-label>
                                <input matInput formControlName="WebsiteName">
                                <mat-error>Please enter a website name.</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Website URL </mat-label>
                                <input matInput formControlName="WebsiteUrl">
                                <mat-error>Please enter a website URL.</mat-error>
                            </mat-form-field>

                            <div class="button-row">
                                <button mat-raised-button color="warn" (click)="removeWebsite(i)">
                                    Remove Website</button>
                            </div>
                        </div>

                        <div class="button-row">
                            <button mat-raised-button color="primary" (click)="addWebsite()">Add Website</button>
                        </div>

                    </div>

                    <h3>Questions</h3>
                    <div formArrayName="Questions">
                        <div *ngFor="let question of Questions.controls; let i=index" [formGroupName]="i">
                            <h4>Question {{ i + 1 }}</h4>

                            <mat-form-field appearance="fill">
                                <mat-label>Question ID</mat-label>
                                <input matInput formControlName="QuestionId">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Question Description</mat-label>
                                <textarea matInput formControlName="QuestionDescription"></textarea>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Question Type</mat-label>
                                <input matInput formControlName="QuestionType">
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Question Answer</mat-label>
                                <textarea matInput formControlName="QuestionAnswer"></textarea>
                            </mat-form-field>

                            <div class="button-row">
                                <button mat-raised-button color="warn" (click)="removeQuestion(i)">Remove
                                    Question</button>
                            </div>

                        </div>

                        <div class="button-row">
                            <button mat-raised-button color="primary" (click)="addQuestion()">Add Question</button>
                        </div>

                    </div>

                    <div formArrayName="SelfIdentification">
                        <h3>Self Identification</h3>
                        <mat-form-field appearance="fill">
                            <mat-label>Gender</mat-label>
                            <input matInput formControlName="Gender">
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Race/Ethnicity</mat-label>
                            <input matInput formControlName="RaceEthnicity">
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Veteran Status</mat-label>
                            <input matInput formControlName="VeteranStatus">
                        </mat-form-field>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="primary" type="submit">Submit</button>
                    <button mat-raised-button color="accent" type="reset"
                        (click)="jobApplicationForm.reset()">Reset</button>
                </mat-card-actions>
            </mat-card>
        </form>
    </div>
</div>