<div class="w3-main" style="margin-left:250px; padding: 64px;">

  <div *ngIf="isLocal">{{ lcaEmployerModelForm.value | json }}</div>

  <div style="display: flex; justify-content: center;">
    <form class="form" [formGroup]="lcaEmployerModelForm" (ngSubmit)="onSearch()">
      <div style="display: flex;">

        <mat-form-field class="example-full-width" appearance="fill" style="margin-right: 10px;">
          <mat-label>Employer</mat-label>
          <input type="text" placeholder="Employer" aria-label="Employer" matInput formControlName="EmployerName"
            [matAutocomplete]="auto" (input)="onEmployerNameInputTypeahead($event)">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of searchResultsAsync | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="fill" style="margin-right: 10px;">
          <mat-label>JobTitle</mat-label>
          <input matInput name="JobTitle" type="text" placeholder="JobTitle" formControlName="JobTitle">
          <mat-error>
            Field must have least 2 characters
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="fill" style="margin-right: 10px;">
          <mat-label>City</mat-label>
          <input matInput name="City" type="text" placeholder="City" formControlName="EmployerCity">
          <mat-error>
            Field must have least 2 characters
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Year</mat-label>
          <mat-select formControlName="Year">
            <mat-option>2022-2019</mat-option>
            <mat-option value="2022">2022</mat-option>
            <mat-option value="2021">2021</mat-option>
            <mat-option value="2020">2020</mat-option>
            <mat-option value="2019">2019</mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-flat-button color="primary"
          style="padding-left:50px; padding-right:50px; margin-left: 10px; height: 55px" (click)=onSearch() type="button">Search</button>

      </div>
    </form>
  </div>

  <div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container matColumnDef="EmployerName">
        <th mat-header-cell *matHeaderCellDef> EmployerName </th>
        <td mat-cell *matCellDef="let element"> {{element.EmployerName}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="JobTitle">
        <th mat-header-cell *matHeaderCellDef> JobTitle </th>
        <td mat-cell *matCellDef="let element"> {{element.JobTitle}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="EmployerCity">
        <th mat-header-cell *matHeaderCellDef> EmployerCity </th>
        <td mat-cell *matCellDef="let element"> {{element.EmployerCity}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="ReceivedDate">
        <th mat-header-cell *matHeaderCellDef> ReceivedDate </th>
        <td mat-cell *matCellDef="let element"> {{element.ReceivedDate}} </td>
      </ng-container>

      <ng-container matColumnDef="WageRateOfPayFrom">
        <th mat-header-cell *matHeaderCellDef> WageRateOfPayFrom </th>
        <td mat-cell *matCellDef="let element"> {{element.WageRateOfPayFrom}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>


</div>