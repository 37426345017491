import { Component } from '@angular/core';

@Component({
  selector: 'app-top500-colleges',
  templateUrl: './top500-colleges.component.html',
  styleUrls: ['./top500-colleges.component.css']
})
export class Top500CollegesComponent {

}
