import { Component } from '@angular/core';

@Component({
  selector: 'app-job-skill-matrix',
  templateUrl: './job-skill-matrix.component.html',
  styleUrls: ['./job-skill-matrix.component.css']
})
export class JobSkillMatrixComponent {

}
