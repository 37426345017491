<nav class="w3-sidebar w3-bar-block w3-collapse w3-large w3-theme-l5 w3-animate-left" id="mySidebar">
    <a href="javascript:void(0)" onclick="w3_close()"
        class="w3-right w3-xlarge w3-padding-large w3-hover-black w3-hide-large" title="Close Menu">
        <i class="fa fa-remove"></i>
    </a>
    <h4 class="w3-bar-item"><b>Job Menu</b></h4>
    <a class="w3-bar-item w3-button w3-hover-black" routerLink="job-fortune-500"
        routerLinkActive="activeNavSideBarButton">Fortune 500</a>
    <a class="w3-bar-item w3-button w3-hover-black" routerLink="job-h1b-data"
        routerLinkActive="activeNavSideBarButton">H-1B Data</a>
    <a class="w3-bar-item w3-button w3-hover-black" routerLink="job-positions"
        routerLinkActive="activeNavSideBarButton">Positions</a>
    <a class="w3-bar-item w3-button w3-hover-black" routerLink="job-application"
        routerLinkActive="activeNavSideBarButton">Application</a>
    <a class="w3-bar-item w3-button w3-hover-black" routerLink="job-bgv"
        routerLinkActive="activeNavSideBarButton">Bgv</a>
    <a class="w3-bar-item w3-button w3-hover-black" routerLink="job-references"
        routerLinkActive="activeNavSideBarButton">References</a>
    <a class="w3-bar-item w3-button w3-hover-black" routerLink="job-referral"
        routerLinkActive="activeNavSideBarButton">Referral</a>
    <a class="w3-bar-item w3-button w3-hover-black" routerLink="job-prep"
        routerLinkActive="activeNavSideBarButton">Prep</a>
    <a class="w3-bar-item w3-button w3-hover-black" routerLink="job-assessments"
        routerLinkActive="activeNavSideBarButton">Assessments</a>
    <a class="w3-bar-item w3-button w3-hover-black" routerLink="job-interviews"
        routerLinkActive="activeNavSideBarButton">Interviews</a>
    <a class="w3-bar-item w3-button w3-hover-black" routerLink="job-skill-matrix"
        routerLinkActive="activeNavSideBarButton">Skill Matrix</a>
</nav>