import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-layouts-nav-bar',
  templateUrl: './layouts-nav-bar.component.html',
  styleUrls: ['./layouts-nav-bar.component.css']
})
export class LayoutsNavBarComponent {

  dropdownStates = [false, false];
  constructor(private oauthService: OAuthService) {
  }

  toggleDropdown(index: number) {
    this.dropdownStates[index] = !this.dropdownStates[index];
  }

  userLogout() {    
    this.oauthService.logOut();
    console.log("clicked logout()");
  }

  isUserLoggedIn() : boolean {    
    console.log("clicked isUserLogout()");
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }

  actionNavigationMenuDisappear() {
    let x = document.getElementById("navDemo");

    if (x!=null && x.className.indexOf("w3-show") == -1) {
      x.className += " w3-show";
    } 
    else if(x!=null) { 
      x.className = x.className.replace(" w3-show", "");
    }
  }

}
