import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { JobLayoutComponent } from './_components/_job/job-layout/job-layout.component';
import { HomeLayoutComponent } from './_components/_home/home-layout/home-layout.component';
import { BookingsLayoutComponent } from './_components/_bookings/bookings-layout/bookings-layout.component';
import { IpLayoutComponent } from './_components/_ip/ip-layout/ip-layout.component';
import { LayoutsErrorComponent } from './_components/_layouts/layouts-error/layouts-error.component';
import { LayoutsPageNotFoundComponent } from './_components/_layouts/layouts-page-not-found/layouts-page-not-found.component';
import { BookingsSkuListComponent } from './_components/_bookings/bookings-sku-list/bookings-sku-list.component';
import { BookingsSkuFormComponent } from './_components/_bookings/bookings-sku-form/bookings-sku-form.component';
import { BookingsOrderListComponent } from './_components/_bookings/bookings-order-list/bookings-order-list.component';
import { BookingsOrderFormComponent } from './_components/_bookings/bookings-order-form/bookings-order-form.component';
import { BookingsChatgptComponent } from './_components/_bookings/bookings-chatgpt/bookings-chatgpt.component';
import { CredentialsComponent } from './_components/_identity/credentials/credentials.component';
import { ChatComponent } from './_components/_chat/chat/chat.component';
import { AuthGuard } from './_guards/auth.guard';
import { RolesEnum } from './_models/RolesEnum';
import { JobH1bDataComponent } from './_components/_job/job-h1b-data/job-h1b-data.component';
import { JobApplicationComponent } from './_components/_job/job-application/job-application.component';
import { JobFortune500Component } from './_components/_job/job-fortune500/job-fortune500.component';
import { JobPositionsComponent } from './_components/_job/job-positions/job-positions.component';
import { AccountSettingsComponent } from './_components/_account/account-settings/account-settings.component';
import { JobReferencesComponent } from './_components/_job/job-references/job-references.component';
import { JobBgvComponent } from './_components/_job/job-bgv/job-bgv.component';
import { JobReferralComponent } from './_components/_job/job-referral/job-referral.component';
import { JobInterviewsComponent } from './_components/_job/job-interviews/job-interviews.component';
import { JobAssessmentsComponent } from './_components/_job/job-assessments/job-assessments.component';
import { JobPrepComponent } from './_components/_job/job-prep/job-prep.component';
import { JobSkillMatrixComponent } from './_components/_job/job-skill-matrix/job-skill-matrix.component';
import { StudyLayoutComponent } from './_components/_study/study-layout/study-layout.component';
import { Top500CollegesComponent } from './_components/_study/top500-colleges/top500-colleges.component';
import { Day1CptCollegesComponent } from './_components/_study/day1cpt-colleges/day1cpt-colleges.component';

const routes: Routes = [
  { path: 'home', component: HomeLayoutComponent },
  {
    path: 'job', component: JobLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'job-fortune-500', component: JobFortune500Component },
      { path: 'job-h1b-data', component: JobH1bDataComponent },
      { path: 'job-positions', component: JobPositionsComponent },
      { path: 'job-application', component: JobApplicationComponent },
      { path: 'job-bgv', component: JobBgvComponent },
      { path: 'job-references', component: JobReferencesComponent },
      { path: 'job-referral', component: JobReferralComponent },
      { path: 'job-interviews', component: JobInterviewsComponent },
      { path: 'job-assessments', component: JobAssessmentsComponent },
      { path: 'job-prep', component: JobPrepComponent },
      { path: 'job-skill-matrix', component: JobSkillMatrixComponent },
      { path: '', component: JobFortune500Component },
    ]
  },
  {
    path: 'study', component: StudyLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'study-top500-colleges', component: Top500CollegesComponent },
      { path: 'study-day1cpt-colleges', component: Day1CptCollegesComponent },
      { path: '', component: Top500CollegesComponent },
    ]
  },
  {
    path: 'bookings', component: BookingsLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'bookings-chatgpt', component: BookingsChatgptComponent },
      { path: 'bookings-sku-list', component: BookingsSkuListComponent },
      { path: 'bookings-sku-form', component: BookingsSkuFormComponent },
      { path: 'bookings-order-list', component: BookingsOrderListComponent },
      { path: 'bookings-order-form', component: BookingsOrderFormComponent },
      { path: '', component: BookingsSkuListComponent },
    ]
  },
  { path: 'ip', component: IpLayoutComponent },
  { path: 'chat', component: ChatComponent, canActivate: [AuthGuard], data: { roles: [RolesEnum.admin] } },
  { path: 'credentials', component: CredentialsComponent },
  { path: 'account-settings', component: AccountSettingsComponent },
  { path: 'error', component: LayoutsErrorComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: LayoutsPageNotFoundComponent },
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    [RouterModule.forRoot(routes)]
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
