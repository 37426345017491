<div class="w3-main" style="padding: 64px;">
    <div>
        <h1>Public IP</h1>
        <app-ip-public></app-ip-public>
    </div>

    <div>
        <h1>Private IP</h1>
        <app-ip-private></app-ip-private>
    </div>
</div>