import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, map, of, startWith } from 'rxjs';
import { JobCompanyModel } from 'src/app/_models/JobCompanyModel';
import { JobUserCompanyModel } from 'src/app/_models/JobUserCompanyModel';
import { LcaEmployerApplicationModel } from 'src/app/_models/LcaEmployerApplicationModel';
import { LcaEmployerModel } from 'src/app/_models/LcaEmployerModel';
import { LcaApiService } from 'src/app/_services/lca-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-job-h1b-data',
  templateUrl: './job-h1b-data.component.html',
  styleUrls: ['./job-h1b-data.component.css']
})

export class JobH1bDataComponent {

  public isLocal: boolean =  environment.isLocal;
  
  lcaEmployerModelForm: FormGroup  = new FormGroup({});

  lcaEmployerModel: LcaEmployerModel  = {};
  lcaEmployerName: string = '';
  lcaEmployerApplicationModel: LcaEmployerApplicationModel[] = []; 

  displayedColumns: string[] = ['EmployerName', 'JobTitle', 'EmployerCity', 'ReceivedDate', 'WageRateOfPayFrom'];
  dataSource : MatTableDataSource<LcaEmployerApplicationModel> = new MatTableDataSource<LcaEmployerApplicationModel>;

  searchResults: string[] = [];
  Years: string[] = ['2022', '2021', '2020', '2019'];


  searchResultsAsync: Observable<string[]> | undefined;  


  constructor(private fb: FormBuilder, private lcaApiService: LcaApiService) { 

    this.lcaEmployerModelForm = this.fb.group({
      EmployerName: [''],
      JobTitle: [''],
      EmployerCity: [''],
      Year: [''],
    });


  }

  onEmployerNameInputTypeahead(event: any) {
    
    const query = event.target.value;

    this.lcaApiService.getTypeaheadEmployerName(query).subscribe({
      next: (n: string[]) => {

        this.searchResults = n;
        this.searchResultsAsync = of(n);

        console.log("Success");
      },
      error: (e: Error) => {
        console.log("error");
      },
      complete() {
        console.log("completed");
      }

    });
  }

  onSearch() {
    
    const formData = this.lcaEmployerModelForm.getRawValue() as LcaEmployerModel;

    console.log(formData);

    this.lcaApiService.getByModel(formData).subscribe({
      next: (n: LcaEmployerApplicationModel[]) => {

        this.dataSource = new MatTableDataSource(n);

        console.log("Success");
      },
      error: (e: Error) => {
        console.log("error");
      },
      complete() {
        console.log("completed");
      }

    });
  }
}
