<nav class="w3-sidebar w3-bar-block w3-collapse w3-large w3-theme-l5 w3-animate-left" id="mySidebar">
  <a href="javascript:void(0)" onclick="w3_close()"
    class="w3-right w3-xlarge w3-padding-large w3-hover-black w3-hide-large" title="Close Menu">
    <i class="fa fa-remove"></i>
  </a>
  <h4 class="w3-bar-item"><b>Bookings Menu</b></h4>

  <a class="w3-bar-item w3-button w3-hover-black" routerLink="bookings-chatgpt"
    routerLinkActive="activeNavSideBarButton">BookingsChatgpt</a>
  <a class="w3-bar-item w3-button w3-hover-black" routerLink="bookings-sku-list"
    routerLinkActive="activeNavSideBarButton">BookingsSkuList</a>
  <a class="w3-bar-item w3-button w3-hover-black" routerLink="bookings-sku-form"
    routerLinkActive="activeNavSideBarButton">BookingsSkuForm</a>
  <a class="w3-bar-item w3-button w3-hover-black" routerLink="bookings-order-list"
    routerLinkActive="activeNavSideBarButton">BookingsOrderList</a>
  <a class="w3-bar-item w3-button w3-hover-black" routerLink="bookings-order-form"
    routerLinkActive="activeNavSideBarButton">BookingsSkuList</a>
</nav>