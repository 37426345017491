import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private returnUrl: string | undefined;
  
  constructor(private router: Router, private oauthService: OAuthService) {}

  canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    routerStateSnapshot: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      if(this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
        return true;
      }
      else{

        this.router.navigate(['/credentials'], { queryParams: { continueRoutePath: routerStateSnapshot.url } });
        return false;

        // this.router.navigate(['/credentials'], { queryParams: { continueRoutePath: '/job' } }); //?continueRoutePath=job
        // this.router.navigate(['/credentials', { login: true }]);
        // this.router.navigate(['/credentials']);
        // return false;
      }
  }
  
}
