import { Component } from '@angular/core';

@Component({
  selector: 'app-study-layout',
  templateUrl: './study-layout.component.html',
  styleUrls: ['./study-layout.component.css']
})
export class StudyLayoutComponent {

}
