import { Component } from '@angular/core';

@Component({
  selector: 'app-bookings-chatgpt',
  templateUrl: './bookings-chatgpt.component.html',
  styleUrls: ['./bookings-chatgpt.component.css']
})
export class BookingsChatgptComponent {
  displayedColumns = ['name', 'age', 'actions'];
  dataSource = [
    { name: 'John Doe', age: 30 },
    { name: 'Jane Doe', age: 28 },
    { name: 'Jim Smith', age: 32 }
  ];

  view() {
        // implement the logic to view an element
  }

  edit() {
    // implement the logic to edit an element
  }

  delete() {
    // implement the logic to delete an element
  }
}
