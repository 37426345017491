import { Component } from '@angular/core';

@Component({
  selector: 'app-layouts-error',
  templateUrl: './layouts-error.component.html',
  styleUrls: ['./layouts-error.component.css']
})
export class LayoutsErrorComponent {

}
