import { Component } from '@angular/core';

@Component({
  selector: 'app-job-layout',
  templateUrl: './job-layout.component.html',
  styleUrls: ['./job-layout.component.css']
})
export class JobLayoutComponent {

}
