<div class="w3-top topNavBar">
    <div class="w3-bar w3-black w3-card">

        <a class="w3-bar-item w3-button w3-padding-large w3-hide-medium w3-hide-large w3-right"
            href="javascript:void(0)" onclick="myFunction()" title="TopMenu"><i class="fa fa-bars"
                style="font-size: large;"></i></a>

        <a routerLink="home" routerLinkActive="activeNavBarButton"
            class="w3-bar-item w3-button w3-padding-large w3-teal"><i class="fa fa-home w3-margin-right"
                style="font-size: large;"></i>SPT</a>

        <a routerLink="job" routerLinkActive="activeNavBarButton"
            class="w3-bar-item w3-button w3-padding-large w3-hide-small">JOB</a>

        <a routerLink="study" routerLinkActive="activeNavBarButton"
            class="w3-bar-item w3-button w3-padding-large w3-hide-small">STUDY</a>

        <a routerLink="bookings" routerLinkActive="activeNavBarButton"
            class="w3-bar-item w3-button w3-padding-large w3-hide-small">BOOK</a>
        <a routerLink="ip" routerLinkActive="activeNavBarButton"
            class="w3-bar-item w3-button w3-padding-large w3-hide-small">IP</a>
        <a routerLink="chat" routerLinkActive="activeNavBarButton"
            class="w3-bar-item w3-button w3-padding-large w3-hide-small">CHAT</a>

        <!-- <a routerLink="credentials" routerLinkActive="activeNavBarButton"
            class="w3-bar-item w3-button w3-padding-large w3-hide-small">CREDENTIALS</a> -->

        <div [hidden]="!isUserLoggedIn()">
            <div class="w3-dropdown-hover w3-hide-small w3-right">
                <button class="w3-padding-large w3-black w3-button " title="More"><i class="fa fa-user"></i> ACCOUNT <i
                        class="fa fa-caret-down"></i></button>

                <div class="w3-dropdown-content w3-bar-block w3-card-4">
                    <a routerLink="account-settings" class="w3-bar-item w3-button">SETTINGS</a>
                    <a class="w3-bar-item w3-button" (click)="userLogout()">LOGOUT</a>
                </div>

            </div>
        </div>

        <!-- <a href="javascript:void(0)" class="w3-padding-large w3-hover-red w3-hide-small w3-right"><i
                class="fa fa-search" style="font-size: large;"></i></a> -->
    </div>
</div>

<div id="navDemo" class="w3-bar-block w3-black w3-hide w3-hide-large w3-hide-medium w3-top topNavBar"
    style="margin-top:46px">
    <div>
        <div class="w3-dropdown-hover">
            <button class="w3-padding-large w3-black w3-button" title="Job" (click)="toggleDropdown(0)">JOB <i
                    class="fa fa-caret-down"></i></button>
            <div class="w3-dropdown-content w3-bar-block w3-card-4" [hidden]="!dropdownStates[0]">
                <a routerLink="job/job-fortune-500" routerLinkActive="activeNavBarButton" class="w3-bar-item w3-button"
                    (click)="actionNavigationMenuDisappear()">Fortune 500</a>
                <a routerLink="job/job-h1b-data" routerLinkActive="activeNavBarButton" class="w3-bar-item w3-button"
                    (click)="actionNavigationMenuDisappear()">H-1B Data</a>
                <a routerLink="job/job-positions" routerLinkActive="activeNavBarButton" class="w3-bar-item w3-button"
                    (click)="actionNavigationMenuDisappear()">Positions</a>
                <a routerLink="job/job-application" routerLinkActive="activeNavBarButton" class="w3-bar-item w3-button"
                    (click)="actionNavigationMenuDisappear()">Application</a>
                <a routerLink="job/job-bgv" routerLinkActive="activeNavBarButton" class="w3-bar-item w3-button"
                    (click)="actionNavigationMenuDisappear()">BGV</a>
                <a routerLink="job/job-references" routerLinkActive="activeNavBarButton" class="w3-bar-item w3-button"
                    (click)="actionNavigationMenuDisappear()">References</a>
                <a routerLink="job/job-referral" routerLinkActive="activeNavBarButton" class="w3-bar-item w3-button"
                    (click)="actionNavigationMenuDisappear()">Referral</a>
                <a routerLink="job/job-prep" routerLinkActive="activeNavBarButton" class="w3-bar-item w3-button"
                    (click)="actionNavigationMenuDisappear()">Prep</a>
                <a routerLink="job/job-assessments" routerLinkActive="activeNavBarButton" class="w3-bar-item w3-button"
                    (click)="actionNavigationMenuDisappear()">Assessments</a>
                <a routerLink="job/job-interviews" routerLinkActive="activeNavBarButton" class="w3-bar-item w3-button"
                    (click)="actionNavigationMenuDisappear()">Interviews</a>
                <a routerLink="job/job-skill-matrix" routerLinkActive="activeNavBarButton" class="w3-bar-item w3-button"
                    (click)="actionNavigationMenuDisappear()">SkillMatrix</a>
            </div>
        </div>
    </div>

    <div>
        <div class="w3-dropdown-hover">
            <button class="w3-padding-large w3-black w3-button" title="Study" (click)="toggleDropdown(0)">Study <i
                    class="fa fa-caret-down"></i></button>
            <div class="w3-dropdown-content w3-bar-block w3-card-4" [hidden]="!dropdownStates[0]">
                <a routerLink="study/study-top500-colleges" routerLinkActive="activeNavBarButton" class="w3-bar-item w3-button"
                    (click)="actionNavigationMenuDisappear()">Top500 Colleges</a>
                <a routerLink="study/study-day1cpt-colleges" routerLinkActive="activeNavBarButton" class="w3-bar-item w3-button"
                    (click)="actionNavigationMenuDisappear()">Day1Cpt Colleges</a>
            </div>
        </div>
    </div>

    <div>
        <div class="w3-dropdown-hover">
            <button class="w3-padding-large w3-black w3-button" title="Book" (click)="toggleDropdown(0)">BOOK <i
                    class="fa fa-caret-down"></i></button>
            <div class="w3-dropdown-content w3-bar-block w3-card-4" [hidden]="!dropdownStates[0]">
                <a routerLink="bookings/bookings-chatgpt" routerLinkActive="activeNavBarButton"
                    class="w3-bar-item w3-button" (click)="actionNavigationMenuDisappear()">BookingsChatgpt</a>
                <a routerLink="bookings/bookings-sku-list" routerLinkActive="activeNavBarButton"
                    class="w3-bar-item w3-button" (click)="actionNavigationMenuDisappear()">BookingsSkuList</a>
                <a routerLink="bookings/bookings-sku-form" routerLinkActive="activeNavBarButton"
                    class="w3-bar-item w3-button" (click)="actionNavigationMenuDisappear()">BookingsSkuForm</a>
                <a routerLink="bookings/bookings-order-list" routerLinkActive="activeNavBarButton"
                    class="w3-bar-item w3-button" (click)="actionNavigationMenuDisappear()">BookingsOrderList</a>
                <a routerLink="bookings/bookings-order-form" routerLinkActive="activeNavBarButton"
                    class="w3-bar-item w3-button" (click)="actionNavigationMenuDisappear()">BookingsSkuList</a>
            </div>
        </div>
    </div>

    <a routerLink="ip" routerLinkActive="activeNavBarButton" (click)="actionNavigationMenuDisappear()"
        class="w3-bar-item w3-button w3-padding-large">IP</a>
    <a routerLink="chat" routerLinkActive="activeNavBarButton" (click)="actionNavigationMenuDisappear()"
        class="w3-bar-item w3-button w3-padding-large">CHAT</a>

    <div [hidden]="!isUserLoggedIn()">
        <div class="w3-dropdown-hover">
            <button class="w3-padding-large w3-black w3-button" title="More" (click)="toggleDropdown(1)"><i
                    class="fa fa-user"></i> ACCOUNT <i class="fa fa-caret-down"></i></button>
            <div class="w3-dropdown-content w3-bar-block w3-card-4" [hidden]="!dropdownStates[1]">
                <a class="w3-bar-item w3-button" (click)="actionNavigationMenuDisappear()">SETTINGS</a>
                <a class="w3-bar-item w3-button" (click)="userLogout()">LOGOUT</a>
            </div>
        </div>
    </div>

</div>