<div class="w3-main" style="margin-left:250px; padding: 64px;">
    <table mat-table [dataSource]="dataSource">
        <!-- Columns -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>
      
        <ng-container matColumnDef="age">
          <th mat-header-cell *matHeaderCellDef>Age</th>
          <td mat-cell *matCellDef="let element">{{element.age}}</td>
        </ng-container>
      
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="view()">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            <button mat-icon-button (click)="edit()">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="delete()">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
      
        <!-- Header and body -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      
</div>