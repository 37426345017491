import { Component } from '@angular/core';

@Component({
  selector: 'app-job-assessments',
  templateUrl: './job-assessments.component.html',
  styleUrls: ['./job-assessments.component.css']
})
export class JobAssessmentsComponent {

}
