import { Component } from '@angular/core';

@Component({
  selector: 'app-home-nav-side-bar',
  templateUrl: './home-nav-side-bar.component.html',
  styleUrls: ['./home-nav-side-bar.component.css']
})
export class HomeNavSideBarComponent {

}
