import { Component, Inject, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs';
import { authCodeFlowConfig } from 'src/app/_configs/auth.config';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-credentials',
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.css']
})
export class CredentialsComponent {
  title : string = 'Credentials';

  queryParamSubscription: Subscription = new Subscription;

  constructor(private oauthService: OAuthService, 
    private location: Location, 
    private router: Router, 
    private activatedRoute: ActivatedRoute, private zone: NgZone) {

    console.log("constructor");

    // authCodeFlowConfig.preserveRequestedRoute = true;
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.loadDiscoveryDocumentAndLogin();
    // this.oauthService.setupAutomaticSilentRefresh();

    // Automatically load user profile
    this.oauthService.events
      .pipe(filter((e) => e.type === 'token_received'))
      .subscribe((_) =>{
        
        this.oauthService.loadUserProfile();
        let continueRoutePathValue = sessionStorage.getItem('requested_route')?.toString().split('=')[1] ?? '';
        
        // const hostname = this.location.onUrlChange;
        // let hostname = location.hostname;

        console.log('continueRoutePath   '+ continueRoutePathValue);

        //Works
        //Explicit delay in `credential` after login for few milliseconds and is required, to capture and update url, 
        //Else use ngAfterViewInit()
        setTimeout(() => {
          this.router.navigateByUrl(decodeURIComponent(continueRoutePathValue));
        }, 0);

        // if(this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()){
        //   this.router.navigateByUrl(decodeURIComponent(continueRoutePathValue));
        // }
        // else{
        //   this.router.navigateByUrl('/error');
        // }


        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.navigate([decodeURIComponent(continueRoutePathValue)], {replaceUrl: true});
        // this.router.navigateByUrl('http://localhost:4206/'+decodeURIComponent(continueRoutePathValue), { replaceUrl: true });

        // this.zone.run(() => {
        //   this.router.navigateByUrl(decodeURIComponent(continueRoutePathValue), { replaceUrl: true });
        // });

        // this.router.events.subscribe(event => {
        //   if (event instanceof NavigationEnd) {
        //     console.log(event.urlAfterRedirects);
        //   }
        // });

      });
  }

  ngOnInit() {
    console.log("ngOnInit");

    //Method1
    // const queryParams = this.activatedRoute.snapshot.queryParams;
    // this.continueRoutePathValue = queryParams['continueRoutePath'];
    // this.qp1 = queryParams['qp1'];
    // this.qp2 = queryParams['qp2'];
    // console.log("ngOnInit print snapshot    " + decodeURIComponent(this.continueRoutePathValue) + "         " + this.qp1 + '     ' + this.qp2);

    //Method2
    // this.queryParamSubscription = this.activatedRoute.queryParams.subscribe(params => {
    //   this.qp1 = params['qp1'];
    //   this.qp2 = params['qp2'];
    //   this.continueRoutePathValue = params['continueRoutePath'];
    //   console.log("ngOnInit print subscribe   " + params['continueRoutePath'] + "    " + params['qp1'] + "    " + params['qp2']);
    // });

    // if(sessionStorage.getItem('access_token') !=null){
    //   this.router.navigate([decodeURIComponent(this.continueRoutePathValue)]);
    // }
  }

  // get userName(): string {

  //   const claims = this.oauthService.getIdentityClaims();
  //   if (!claims) return "null";
  //   return claims['given_name'];
  // }

  // get idToken(): string {
  //   return this.oauthService.getIdToken();
  // }

  // get accessToken(): string {

  //   //requested_route?queryParams, redirect to origin 
  //   let continueRoutePathValue = sessionStorage.getItem('requested_route')?.toString().split('=')[1] ?? '';
  //   console.log('continueRoutePath   '+ continueRoutePathValue);
  //   this.router.navigate([decodeURIComponent(continueRoutePathValue)]);

  //   return this.oauthService.getAccessToken();
  // }

  refresh() {
    // this.oauthService.refreshToken();
    console.log("disabled refresh()");
  }

  reload() {
    console.log("disabled reload()");
  }


  logout() {
    this.oauthService.logOut();
    console.log("disabled logout()");
  }
}
