import { Component } from '@angular/core';

@Component({
  selector: 'app-job-referral',
  templateUrl: './job-referral.component.html',
  styleUrls: ['./job-referral.component.css']
})
export class JobReferralComponent {

}
