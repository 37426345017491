<div class="w3-main" style="margin-left:250px">
    <footer id="myFooter">
        <div class="w3-container w3-theme-l2 w3-padding-32">
            <h4>Footer</h4>
        </div>
    
        <div class="w3-container w3-theme-l1">
            <p>Powered by <a href="https://www.w3schools.com/w3css/default.asp" target="_blank">w3.css</a></p>
        </div>
    </footer>
</div>
