import { Component } from '@angular/core';

@Component({
  selector: 'app-job-bgv',
  templateUrl: './job-bgv.component.html',
  styleUrls: ['./job-bgv.component.css']
})
export class JobBgvComponent {

}
