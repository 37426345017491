import { Component } from '@angular/core';

@Component({
  selector: 'app-ip-public',
  templateUrl: './ip-public.component.html',
  styleUrls: ['./ip-public.component.css']
})
export class IpPublicComponent {
  title = 'ip';

  ip: string = "";


  constructor() {

    const Http = new XMLHttpRequest();
    const url='https://api.ipify.org';
    Http.open("GET", url);
    Http.send();
    
    Http.onreadystatechange = (e) => {
      this.ip = Http.responseText;
    }
  }
}
