import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LcaEmployerApplicationModel } from '../_models/LcaEmployerApplicationModel';
import { LcaEmployerModel } from '../_models/LcaEmployerModel';

@Injectable({
  providedIn: 'root'
})
export class LcaApiService {

  private _sptUrl: string = environment.appSptApiServerUrl+'/api/v1.1';

  constructor(private httpClient: HttpClient) { }

  getTypeaheadEmployerName(query: string){  //: Observable<string[]> 

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+sessionStorage.getItem('access_token')
      })
    };

    const url = `${this._sptUrl}/LcaCachedEmployerNameListTypeahead?employerNameSearch=${query}`

    return this.httpClient.get<string[]>(url, httpOptions).pipe(
      retry(1), // retry a failed request up to 3 times
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
          return throwError(() => error);
        } 
        else {
          // The backend returned an unsuccessful response code.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
          return throwError(() => error);
        }
      })
    );
  }


  getByModel(item: LcaEmployerModel) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization':'Bearer '+sessionStorage.getItem('access_token')
      })
    };


    const url = `${this._sptUrl}/LcaEmployerApplications?employerName=${item.EmployerName}`;

    return this.httpClient.get<LcaEmployerApplicationModel[]>(url, httpOptions).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
          return throwError(() => error);
        } 
        else {
          // The backend returned an unsuccessful response code.
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
          return throwError(() => error);
        }
      })
    );
  }
}
