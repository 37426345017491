import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { JobCompanyModel } from 'src/app/_models/JobCompanyModel';
import { JobCompanyApiService } from 'src/app/_services/job-company-api.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { JobCompanyCreateModel } from 'src/app/_models/JobCompanyCreateModel';
import { FormsModule } from '@angular/forms';
import { FormGroup, FormControl, FormGroupDirective } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { JobCompanyCreateResponseModel } from 'src/app/_models/JobCompanyCreateResponseModel';
import { Router } from '@angular/router';
import { JobFlagCompanyForUserModel } from 'src/app/_models/JobFlagCompanyForUserModel';
import { JobUserCompanyModel } from 'src/app/_models/JobUserCompanyModel';
import { AuthService } from 'src/app/_services/auth.service';
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-job-fortune500',
  templateUrl: './job-fortune500.component.html',
  styleUrls: ['./job-fortune500.component.css']
})
export class JobFortune500Component {
  // displayedColumns: string[] = ['Select', 'SNO', 'Id', 'Name', 'PrimaryIndustry', 'DomainName', 'JobSearchUrl', 'IsFlagged', 'Actions'];
  displayedColumns: string[] = ['Select', 'Name', 'PrimaryIndustry', 'DomainName', 'JobSearchUrl', 'IsFlagged', 'Actions'];
  dataSource: MatTableDataSource<JobUserCompanyModel> = new MatTableDataSource<JobUserCompanyModel>;
  selection = new SelectionModel<JobUserCompanyModel>(true, []);

  isLoading = false;

  showFirstLastButtons = true;
  length = 1000;
  pageSize = 5;
  pageIndex = 0;
  pageEvent: PageEvent = new PageEvent();
  pageSizeOptions = [5, 10, 100, 1000];


  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) private sort!: MatSort;
  @ViewChild('JobCompanyAddFormId') private JobCompanyAddFormIdFormGroupDirective!: FormGroupDirective;

  public _jobCompanyAddModel: JobCompanyCreateModel = {};
  public _successResponse: any = {};
  public _errorResponse: any = {};

  public _showSuccessAlert: boolean = false;
  public _showDangerAlert: boolean = false;
  public _showInfoAlert: boolean = false;
  public _showWarningAlert: boolean = false;

  public isLocal: boolean =  environment.isLocal;


  public _editingRowId = "";

  constructor(private _jobCompanyApiService: JobCompanyApiService,
    public _authService: AuthService,
    private router: Router,
    private _liveAnnouncer: LiveAnnouncer) {

    if (!_authService.isAdmin()) {
      this.displayedColumns.pop();
    }
  }


  ngOnInit(): void {
    // if (sessionStorage.getItem('access_token') != null)
    this.onGetAllByUser();
    // else {
    //   this.router.navigate(['/credentials'], { queryParams: { continueRoutePath: '/job' } }); //?continueRoutePath=job
    //   // this._authService.login();
    // }
  }

  onReloadBtnByUser() {
    this.isLoading = true;

    this._jobCompanyApiService.getAllByUser().subscribe(responseData => {
      this.dataSource = new MatTableDataSource(responseData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

      this._errorResponse = {};

      this._showInfoAlert = false;
      this._showDangerAlert = false;
      this._showSuccessAlert = false;
      this._showWarningAlert = false;

      this.isLoading = false;

      console.log("onReloadBtnByUser() Success response received");
    });

    console.log("onReloadBtnByUser() for Refresh");
  }

  onReloadBtn() {
    this.isLoading = true;

    this._jobCompanyApiService.getAll().subscribe(responseData => {
      this.dataSource = new MatTableDataSource(responseData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

      this._errorResponse = {};

      this._showInfoAlert = false;
      this._showDangerAlert = false;
      this._showSuccessAlert = false;
      this._showWarningAlert = false;

      this.isLoading = false;

      console.log("onReloadBtn() Success response received");
    });

    console.log("onReloadBtn() for Refresh");
  }

  onGet() {

  }

  onGetAllByUser() {
    this.isLoading = true;

    this._jobCompanyApiService.getAllByUser().subscribe({
      next: (n: JobUserCompanyModel[]) => {
        this.dataSource = new MatTableDataSource(n);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        console.log("onGetAllByUser() Success response received");

        this.isLoading = false;

      },
      error: (e: Error) => {
        this._errorResponse = e;
        this._successResponse = {};
        this._showDangerAlert = true;
      },
      complete() {
        console.log("onGetAllByUser() Completed");
      }

    });
  }

  onGetAll() {
    this.isLoading = true;

    this._jobCompanyApiService.getAll().subscribe({
      next: (n: JobCompanyModel[]) => {
        this.dataSource = new MatTableDataSource(n);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        console.log("onGetAll() Success response received");

        this.isLoading = false;

      },
      error: (e: Error) => {
        this._errorResponse = e;
        this._successResponse = {};
        this._showDangerAlert = true;
      },
      complete() {
        console.log("onGetAll() Completed");
      }

    });
  }

  onPost() { ////JSON.stringify(item) this is very essential as Price and Units
    this._jobCompanyApiService.postByModel(this._jobCompanyAddModel).subscribe({
      next: (n: JobCompanyCreateResponseModel) => {

        this._successResponse = n;
        this._errorResponse = {};

        this._showDangerAlert = false;
        this._showSuccessAlert = true;
        this._showWarningAlert = false;
        this._showInfoAlert = false;

        console.log("onPost() Success response received");

        this.JobCompanyAddFormIdFormGroupDirective.resetForm();

        this.onGetAllByUser();
        console.log("called onGetAllByUser() for Refresh");
      },
      error: (e: Error) => {
        this._errorResponse = e;
        this._successResponse = {};
        this._showDangerAlert = true;
        this._showSuccessAlert = false;
        this._showWarningAlert = false;
        this._showInfoAlert = false;
      },
      complete() {
        console.log("onPost() Completed");
      }
    });
  }

  onSave(id: string, name: string, domainName: string, primaryIndustry: string, jobSearchUrl: string, flag: boolean) {

    let jobCompanyModel: JobCompanyModel = { Id: id, Name: name, DomainName: domainName, PrimaryIndustry: primaryIndustry, JobSearchUrl: jobSearchUrl, IsFlagged: flag };

    this._jobCompanyApiService.editByModel(jobCompanyModel).subscribe({
      next: () => {

        this._editingRowId = "";

        this._successResponse = "Successfully modified";
        this._errorResponse = {};

        this._showDangerAlert = false;
        this._showSuccessAlert = false;
        this._showWarningAlert = false;
        this._showInfoAlert = true;

        console.log("onSave() Success response received");

        this.JobCompanyAddFormIdFormGroupDirective.resetForm();

        this.onGetAllByUser();
        console.log("called onGetAllByUser() for Refresh");
      },
      error: (e: Error) => {
        this._errorResponse = e;
        this._successResponse = {};
        this._showDangerAlert = true;
        this._showSuccessAlert = false;
        this._showWarningAlert = false;
        this._showInfoAlert = false;
      },
      complete() {
        console.log("onSave() Completed");
      }
    });
  }

  onEdit(id: string) {
    this._editingRowId = id;
    console.log("onEdit() Completed");
  }

  onCancel() {
    this._editingRowId = "";
    this.onReloadBtnByUser();
    console.log("onCancel() Completed");
  }

  onDelete(id: string) {
    if (confirm("Delete!")) {
      this._jobCompanyApiService.deleteById(id).subscribe({
        next: (n) => {

          this._successResponse = "204 Success! Successfully deleted record";
          this._errorResponse = {};

          this._showWarningAlert = true;
          this._showSuccessAlert = false;
          this._showDangerAlert = false;
          this._showInfoAlert = false;

          console.log("onDelete() Success response received");

          this.onGetAllByUser();
          console.log("called onGetAllByUser() for Refresh");
        },
        error: (e: Error) => {
          this._errorResponse = e;
          this._successResponse = {};

          this._showDangerAlert = true;
          this._showWarningAlert = false;
          this._showSuccessAlert = false;
          this._showInfoAlert = false;
        },
        complete() {
          console.log("onDelete() Completed");
        }
      });
    }
  }


  onFlag(id: string, flag: boolean) {

    let jobCompanyModel: JobCompanyModel = { Id: id, IsFlagged: flag };

    this._jobCompanyApiService.editFlagByModel(jobCompanyModel).subscribe({
      next: () => {

        this._successResponse = "Flagged Successfully";
        this._errorResponse = {};

        this._showDangerAlert = false;
        this._showSuccessAlert = false;
        this._showWarningAlert = false;
        this._showInfoAlert = false;

        console.log("onFlag() Success response received");

        this.JobCompanyAddFormIdFormGroupDirective.resetForm();

        this.onGetAll();
        console.log("called onGetAll() for Refresh");
      },
      error: (e: Error) => {
        this._errorResponse = e;
        this._successResponse = {};
        this._showDangerAlert = true;
        this._showSuccessAlert = false;
        this._showWarningAlert = false;
        this._showInfoAlert = false;
      },
      complete() {
        console.log("onFlag() Completed");
      }
    });
  }

  onFlagByUser(companyId: string, userId: string, flag: boolean) {

    let jobFlagCompanyForUserModel: JobFlagCompanyForUserModel = { CompanyId: companyId, UserId: userId, IsFlagged: flag };

    this._jobCompanyApiService.flagCompanyForUser(jobFlagCompanyForUserModel).subscribe({
      next: () => {

        this._successResponse = "Flagged Successfully";
        this._errorResponse = {};

        this._showDangerAlert = false;
        this._showSuccessAlert = false;
        this._showWarningAlert = false;
        this._showInfoAlert = false;

        console.log("onFlagByUser() Success response received");

        this.JobCompanyAddFormIdFormGroupDirective.resetForm();

        this.onGetAllByUser();
        console.log("called onGetAll() for Refresh");
      },
      error: (e: Error) => {
        this._errorResponse = e;
        this._successResponse = {};
        this._showDangerAlert = true;
        this._showSuccessAlert = false;
        this._showWarningAlert = false;
        this._showInfoAlert = false;
      },
      complete() {
        console.log("onFlagByUser() Completed");
      }
    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row aria label*/
  checkboxLabel(row?: JobCompanyModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row id ${row.Id}`;
  }
}
