import { Component } from '@angular/core';

@Component({
  selector: 'app-job-prep',
  templateUrl: './job-prep.component.html',
  styleUrls: ['./job-prep.component.css']
})
export class JobPrepComponent {

}
