import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JobLayoutComponent } from './_components/_job/job-layout/job-layout.component';
import { HomeNavSideBarComponent } from './_components/_home/home-nav-side-bar/home-nav-side-bar.component';
import { HomeComponent } from './_components/_home/home/home.component';
import { HomeLayoutComponent } from './_components/_home/home-layout/home-layout.component';
import { BookingsLayoutComponent } from './_components/_bookings/bookings-layout/bookings-layout.component';
import { BookingsNavSideBarComponent } from './_components/_bookings/bookings-nav-side-bar/bookings-nav-side-bar.component';
import { BookingsSkuFormComponent } from './_components/_bookings/bookings-sku-form/bookings-sku-form.component';
import { BookingsSkuListComponent } from './_components/_bookings/bookings-sku-list/bookings-sku-list.component';
import { BookingsOrderFormComponent } from './_components/_bookings/bookings-order-form/bookings-order-form.component';
import { BookingsOrderListComponent } from './_components/_bookings/bookings-order-list/bookings-order-list.component';
import { IpLayoutComponent } from './_components/_ip/ip-layout/ip-layout.component';
import { IpPublicComponent } from './_components/_ip/ip-public/ip-public.component';
import { IpPrivateComponent } from './_components/_ip/ip-private/ip-private.component';
import { LayoutsNavBarComponent } from './_components/_layouts/layouts-nav-bar/layouts-nav-bar.component';
import { LayoutsErrorComponent } from './_components/_layouts/layouts-error/layouts-error.component';
import { LayoutsPageNotFoundComponent } from './_components/_layouts/layouts-page-not-found/layouts-page-not-found.component';
import { LayoutsFooterComponent } from './_components/_layouts/layouts-footer/layouts-footer.component';
import { FormsModule } from '@angular/forms';
import { BookingsChatgptComponent } from './_components/_bookings/bookings-chatgpt/bookings-chatgpt.component';
import { CredentialsComponent } from './_components/_identity/credentials/credentials.component';
import { CredentialsLayoutComponent } from './_components/_identity/credentials-layout/credentials-layout.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ChatLayoutComponent } from './_components/_chat/chat-layout/chat-layout.component';
import { ChatNavSideBarComponent } from './_components/_chat/chat-nav-side-bar/chat-nav-side-bar.component';
import { ChatComponent } from './_components/_chat/chat/chat.component';
import { UserLayoutComponent } from './_components/_user/user-layout/user-layout.component';
import { UserMenuComponent } from './_components/_user/user-menu/user-menu.component';
import { MaterialModule } from './material.module';
import { JobNavSideBarComponent } from './_components/_job/job-nav-side-bar/job-nav-side-bar.component';
import { JobH1bDataComponent } from './_components/_job/job-h1b-data/job-h1b-data.component';
import { JobFortune500Component } from './_components/_job/job-fortune500/job-fortune500.component';
import { JobApplicationComponent } from './_components/_job/job-application/job-application.component';
import { JobPositionsComponent } from './_components/_job/job-positions/job-positions.component';
import { AccountSettingsComponent } from './_components/_account/account-settings/account-settings.component';
import { AccountAccessComponent } from './_components/_account/account-access/account-access.component';
import { ReactiveFormsModule } from '@angular/forms';
import { JobBgvComponent } from './_components/_job/job-bgv/job-bgv.component';
import { JobInterviewsComponent } from './_components/_job/job-interviews/job-interviews.component';
import { JobReferencesComponent } from './_components/_job/job-references/job-references.component';
import { JobReferralComponent } from './_components/_job/job-referral/job-referral.component';
import { JobAssessmentsComponent } from './_components/_job/job-assessments/job-assessments.component';
import { JobPrepComponent } from './_components/_job/job-prep/job-prep.component';
import { JobSkillMatrixComponent } from './_components/_job/job-skill-matrix/job-skill-matrix.component';
import { StudyNavSideBarComponent } from './_components/_study/study-nav-side-bar/study-nav-side-bar.component';
import { StudyLayoutComponent } from './_components/_study/study-layout/study-layout.component';
import { Top500CollegesComponent } from './_components/_study/top500-colleges/top500-colleges.component';
import { Day1CptCollegesComponent } from './_components/_study/day1cpt-colleges/day1cpt-colleges.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    JobLayoutComponent,
    HomeNavSideBarComponent,
    HomeLayoutComponent,
    BookingsLayoutComponent,
    BookingsNavSideBarComponent,
    BookingsSkuFormComponent,
    BookingsSkuListComponent,
    BookingsOrderFormComponent,
    BookingsOrderListComponent,
    IpLayoutComponent,
    IpPublicComponent,
    IpPrivateComponent,
    LayoutsNavBarComponent,
    LayoutsErrorComponent,
    LayoutsPageNotFoundComponent,
    LayoutsFooterComponent,
    BookingsChatgptComponent,
    CredentialsComponent,
    CredentialsLayoutComponent,
    ChatLayoutComponent,
    ChatNavSideBarComponent,
    ChatComponent,
    UserLayoutComponent,
    UserMenuComponent,
    JobNavSideBarComponent,
    JobH1bDataComponent,
    JobFortune500Component,
    JobApplicationComponent,
    JobPositionsComponent,
    AccountSettingsComponent,
    AccountAccessComponent,
    JobBgvComponent,
    JobInterviewsComponent,
    JobReferencesComponent,
    JobReferralComponent,
    JobAssessmentsComponent,
    JobPrepComponent,
    JobSkillMatrixComponent,
    StudyNavSideBarComponent,
    StudyLayoutComponent,
    Top500CollegesComponent,
    Day1CptCollegesComponent,
  ],
  imports: [
    OAuthModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
