import { Component } from '@angular/core';

@Component({
  selector: 'app-job-nav-side-bar',
  templateUrl: './job-nav-side-bar.component.html',
  styleUrls: ['./job-nav-side-bar.component.css']
})
export class JobNavSideBarComponent {

}
