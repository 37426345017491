export const environment = {
    appName: 'SptWebApp',
    isLocal: false,
    appEnvironment: 'prod',
    appHostUrl: 'https://spt.src.xyz',
    appSptApiUrl: 'https://sptapi.src.xyz',
    appSptApiLocalUrl: 'https://localhost:7210',
    appSptApiServerUrl: 'https://sptapi.src.xyz',
    idpAuthIssuer: 'https://auth.src.xyz',
    idpAuthTokenEndpoint: 'https://auth.src.xyz/connect/token',
    idpAuthAuthorizeEndpoint: 'https://auth.src.xyz/connect/authorize',
    idpAuthClientId: 'SptWebApp_ClientId',
    idpAuthClientName: 'SptWebApp',
    idpAuthRedirectUri: 'https://spt.src.xyz/credentials',
    idpAuthScope: 'SptWebApi_ApiScope openid profile email roles',
};