import { Component } from '@angular/core';

@Component({
  selector: 'app-layouts-footer',
  templateUrl: './layouts-footer.component.html',
  styleUrls: ['./layouts-footer.component.css']
})
export class LayoutsFooterComponent {

}
