import { Component } from '@angular/core';

@Component({
  selector: 'app-job-positions',
  templateUrl: './job-positions.component.html',
  styleUrls: ['./job-positions.component.css']
})
export class JobPositionsComponent {

}
