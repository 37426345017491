import { Component } from '@angular/core';

@Component({
  selector: 'app-job-interviews',
  templateUrl: './job-interviews.component.html',
  styleUrls: ['./job-interviews.component.css']
})
export class JobInterviewsComponent {

}
