import { Component } from '@angular/core';

@Component({
  selector: 'app-bookings-nav-side-bar',
  templateUrl: './bookings-nav-side-bar.component.html',
  styleUrls: ['./bookings-nav-side-bar.component.css']
})
export class BookingsNavSideBarComponent {

}
