<div class="w3-main" style="margin-left:250px; padding: 64px;">

    <div *ngIf="isLocal">
      <p>FormValue: {{JobCompanyAddFormId.value | json}}</p>
      <p>ModelValue: {{_jobCompanyAddModel | json}}</p>
      <p>SuccessResponse: {{_successResponse | json}}</p>
      <p>ErrorResponse: {{_errorResponse | json}}</p>
    </div>
  
    <div>
      <div class="alert danger" *ngIf="_showDangerAlert">
        <span class="closebtn" onclick="this.parentElement.style.display='none';"> &times; </span>
        <p><strong>{{_errorResponse["status"]}} Error!</strong> {{_errorResponse.error["title"]}} </p>
      </div>
  
      <div class="alert success" *ngIf="_showSuccessAlert">
        <span class="closebtn" onclick="this.parentElement.style.display='none';"> &times; </span>
        <p><strong>201 Success!</strong> added new company with Id "{{_successResponse["Id"]}}" </p>
      </div>
  
      <div class="alert warning" *ngIf="_showWarningAlert">
        <span class="closebtn" onclick="this.parentElement.style.display='none';"> &times; </span>
        <p><strong>204 Success!</strong> {{_successResponse}}" </p>
      </div>
  
      <div class="alert info" *ngIf="_showInfoAlert">
        <span class="closebtn" onclick="this.parentElement.style.display='none';"> &times; </span>
        <p><strong>204 Success!</strong> Data succesfully updated </p>
      </div>
    </div>
  
    <div [hidden]="!_authService.isAdmin()">
      <form #JobCompanyAddFormId="ngForm" id="ngForm">
        <div>
          <h1>JobCompanyList</h1>
        </div>
  
        <div>
          <div style="display: flex;">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>CompanyName</mat-label>
              <input matInput name="CompanyName" #CompanyName="ngModel" type="text" placeholder="CompanyName"
                [(ngModel)]="_jobCompanyAddModel.Name" minlength="2" required>
              <mat-error *ngIf="CompanyName.invalid && (CompanyName.dirty || CompanyName.touched)">
                Field must have least 2 characters
              </mat-error>
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>DomainName</mat-label>
              <input matInput name="DomainName" #DomainName="ngModel" type="text" placeholder="DomainName"
                [(ngModel)]="_jobCompanyAddModel.DomainName" minlength="2" required>
              <mat-error *ngIf="DomainName.invalid && (DomainName.dirty || DomainName.touched)">
                Field must have least 2 characters
              </mat-error>
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>PrimaryIndustry</mat-label>
              <input matInput name="PrimaryIndustry" #PrimaryIndustry="ngModel" type="text" placeholder="PrimaryIndustry"
                [(ngModel)]="_jobCompanyAddModel.PrimaryIndustry" minlength="2" required>
              <mat-error *ngIf="PrimaryIndustry.invalid && (PrimaryIndustry.dirty || PrimaryIndustry.touched)">
                Field must have least 2 characters
              </mat-error>
            </mat-form-field>
  
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>JobSearchUrl</mat-label>
              <input matInput name="JobSearchUrl" #JobSearchUrl="ngModel" type="text" placeholder="JobSearchUrl"
                [(ngModel)]="_jobCompanyAddModel.JobSearchUrl" minlength="2" required>
              <mat-error *ngIf="JobSearchUrl.invalid && (JobSearchUrl.dirty || JobSearchUrl.touched)">
                Field must have least 2 characters
              </mat-error>
            </mat-form-field>
          </div>
  
          <div class="inline-add-reload-deleteSelected-goto">
            <button (click)=onPost() [disabled]="!JobCompanyAddFormId.form.valid" style="margin-bottom: 20px;"
              class="w3-button w3-black w3-padding-large" type="button">Add</button>
            <button (click)=onReloadBtnByUser() style="margin-bottom: 20px; margin-left: 20px;"
              class="w3-button w3-black w3-padding-large" type="button">Reload Data</button>
            <!-- <button (click)=onDelete() style="margin-bottom: 20px; margin-left: 20px;"
              class="w3-button w3-black w3-padding-large" type="button">Delete Selected</button> -->
          </div>
        </div>
      </form>
    </div>
  
  
  
    <div>
      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search with any field" #input>
      </mat-form-field>
  
      <div class="mat-elevation-z8">
  
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
  
          <ng-container matColumnDef="Select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="SNO">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by SNO"> SNO </th>
            <td mat-cell *matCellDef="let i = index"> {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i +
              this.paginator.pageIndex * this.paginator.pageSize}} </td> -->
            <!--<td mat-cell *matCellDef="let element"> {{dataSource.filteredData.indexOf(element)+1}} </td> -->
          </ng-container>
  
          <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id"> Id </th>
            <td mat-cell *matCellDef="let row"> {{row.Id}} </td>
          </ng-container>
  
          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name" class="sticky-column sticky-column-header"> Name </th>
            <td mat-cell *matCellDef="let row" class="sticky-column sticky-column-cell">
              <span *ngIf="!(row.Id ===_editingRowId)">{{row.Name}}</span>
              <input *ngIf="row.Id ===_editingRowId" matInput [(ngModel)]="row.Name"
                class="w3-input w3-border-0 w3-light-grey">
            </td>
          </ng-container>
  
          <ng-container matColumnDef="DomainName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by DomainName"> DomainName
            </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="!(row.Id ===_editingRowId)">{{row.DomainName}}</span>
              <input *ngIf="(row.Id ===_editingRowId)" matInput [(ngModel)]="row.DomainName"
                class="w3-input w3-border-0 w3-light-grey">
            </td>
          </ng-container>
  
          <ng-container matColumnDef="PrimaryIndustry">
            <th mat-header-cell *matHeaderCellDef appearance="outline" mat-sort-header sortActionDescription="Sort by PrimaryIndustry"> PrimaryIndustry </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="!(row.Id ===_editingRowId)">{{row.PrimaryIndustry}} </span>
              <input *ngIf="(row.Id ===_editingRowId)" matInput [(ngModel)]="row.PrimaryIndustry"
                class="w3-input w3-border-0 w3-light-grey">
            </td>
          </ng-container>
  
          <ng-container matColumnDef="JobSearchUrl">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by JobSearchUrl">
              JobSearchUrl </th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="!(row.Id ===_editingRowId)"><a href="{{row.JobSearchUrl}}" target="_blank">link</a></span>
              <input *ngIf="(row.Id ===_editingRowId)" matInput [(ngModel)]="row.JobSearchUrl"
                class="w3-input w3-border-0 w3-light-grey">
            </td>
          </ng-container>
  
          <div class="IsFlaggedColumnColor">
            <ng-container matColumnDef="IsFlagged">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by IsFlagged" class="IsFlaggedColumnColor" style="background-color: rgb(240, 240, 240)">IsFlagged</th>
              <td mat-cell *matCellDef="let row" style="background-color: rgb(240, 240, 240)">
                <mat-slide-toggle [checked]="row.IsFlagged" (change)="onFlagByUser(row.Id, row.UserId, !row.IsFlagged)"></mat-slide-toggle>
              </td>
            </ng-container>
          </div>
  
          <div [hidden]="!_authService.isAdmin()">
            <ng-container matColumnDef="Actions">
              <th mat-header-cell *matHeaderCellDef>Actions
              </th>
              <td mat-cell *matCellDef="let row">
                <div class="inline-view-edit-delete-btns">
                  <button *ngIf="(row.Id ===_editingRowId)" mat-raised-button color="primary" (click)="onSave(row.Id, row.Name, row.DomainName, row.PrimaryIndustry, row.JobSearchUrl, row.IsFlagged)">
                    <mat-icon>save</mat-icon>
                  </button>
                  <button *ngIf="!(row.Id ===_editingRowId)" mat-icon-button (click)="onEdit(row.Id)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button *ngIf="(row.Id ===_editingRowId)" mat-icon-button (click)="onCancel()">
                    <mat-icon>cancel</mat-icon>
                  </button>
                  <button *ngIf="!(row.Id ===_editingRowId)" mat-icon-button (click)="onDelete(row.Id)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
          </div>
  
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
          </tr>
        </table>
  
        <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
          <mat-progress-spinner color="primary" mode="indeterminate">
          </mat-progress-spinner>
        </mat-card>
  
        <mat-paginator #paginator [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageSizeOptions"
          [pageSize]="pageSize" [pageIndex]="pageIndex" (page)="handlePageEvent($event)" [length]="length"
          aria-label="Select page of table">
        </mat-paginator>
      </div>
    </div>
  </div>