import { Component } from '@angular/core';

@Component({
  selector: 'app-job-references',
  templateUrl: './job-references.component.html',
  styleUrls: ['./job-references.component.css']
})
export class JobReferencesComponent {

}
