import { Component } from '@angular/core';

@Component({
  selector: 'app-day1cpt-colleges',
  templateUrl: './day1cpt-colleges.component.html',
  styleUrls: ['./day1cpt-colleges.component.css']
})
export class Day1CptCollegesComponent {

}
