import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SkuModel } from '../_models/SkuModel';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};


@Injectable({
  providedIn: 'root'
})
export class SkuApiService {

  private _apiUrl: string = "https://sptapi.src.xyz/api/v0.1/SkuGetAll";

  constructor(private httpClient: HttpClient) { }

  skuGetAll(): Observable<SkuModel[]> {
    return this.httpClient.get<SkuModel[]>(this._apiUrl);
  }
}
