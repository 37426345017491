import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from '../_configs/auth.config';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private oauthService: OAuthService) { }

  isAdmin(): boolean{
    let jwt_id_token  = this.oauthService.getIdToken();
    
    if(jwt_id_token !== null){
      let jwtData = jwt_id_token?.split('.')[1];
      let decodedJwtJsonData = window.atob(jwtData);
      let decodedJwtData = JSON.parse(decodedJwtJsonData);
      let jwt_roles: string[] = decodedJwtData?.role || [];
      let isAdmin = jwt_roles.includes("spt.admin");
      return isAdmin;
    }
    // console.log("IsAdmin: "+ isAdmin);

    return false;
  }

  // login(){
  //   this.oauthService.configure(authCodeFlowConfig);
  //   this.oauthService.loadDiscoveryDocumentAndLogin();
  //   // this.oauthService.setupAutomaticSilentRefresh();

  //   // Automatically load user profile
  //   this.oauthService.events
  //     .pipe(filter((e) => e.type === 'token_received'))
  //     .subscribe((_) => this.oauthService.loadUserProfile());
  // }

  // loadProfile(){
  //   this.oauthService.configure(authCodeFlowConfig);
  //   this.oauthService.loadDiscoveryDocumentAndLogin();
  //   this.oauthService.events
  //     .pipe(filter((e) => e.type === 'token_received'))
  //     .subscribe((_) => this.oauthService.loadUserProfile());
  // }

  // logout() {
  //   this.oauthService.logOut();
  //   console.log("clicked logout()");
  // }
}
